import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import ServiceListItem from '../../components/framework/service-list-item';



const ServiceList = () => {
	const result = useStaticQuery(graphql`
		query {
			allService(filter: { active: { eq: true } }) {
				edges {
					node {
						currency {
							code
						}
						name
						partner {
							id
						}
						price_from
						product {
							type
						}
						slug
						translation_key
					}
				}
			}
		}
	`);

	const services = result.allService.edges
		.filter((value) => {
			if (value.node.partner.id != 'zample-nl') {
				return false;
			}
			return true;
		})
		.map((value) => {
			return value.node;
		});

	return (
		<>
			<section id="service-list">
				<div className="row">
					{services.map((item, index) => {
						return (
							<div
								className="col-xl-4 col-12 col-md-6 pe-1 mb-3 mb-xl-0 mt-2 mt-lg-0"
								key={index}
							>
								<ServiceListItem item={item} />
							</div>
						);
					})}
				</div>
			</section>
		</>
	);
};

export default ServiceList;
