import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import CtaLink from '../../components/framework/cta-link';
import ContainerDonts from '../../components/framework/container-donts';
import ServiceList from '../../components/framework/service-list';
import Framework from '../../components/framework';
import Seo from '../../components/framework/seo';
import withLocation from '../../middleware/withLocation';

const Products = ({ location }) => {
	const { t } = useTranslation('services');

	return (
		<Framework location={location}>
			<Seo
				description={t('meta_description')}
				tags={t('meta_keywords')}
				title={t('title')}
			/>

			<div className="container mt-3 pt-lg-5">
				<h1 className="h2">{t('t1')}</h1>
				<p className="text-secondary">{t('t2')}</p>
				<section id="products">
					<div className="mt-5">
						<ServiceList />
					</div>
				</section>
				<h2 className="h4 mt-4 mt-lg-5">{t('t3')}</h2>
				<p className="text-secondary small">{t('t4')}</p>
				<div className="row mt-4 justify-content-center my-3">
					<div className="col-lg-6">
						<h3 className="h5 mt-3">{t('t5')}</h3>
						<ul>
							<li>{t('t6')}</li>
							<li>{t('t7')}</li>
						</ul>
						<CtaLink label={t('t8')} linkUrl="/book" />
					</div>
					<div className="col-lg-6">
						<h3 className="h5 mt-3">{t('t9')}</h3>
						<ul>
							<li>{t('t10')}</li>
							<li>{t('t11')}</li>
						</ul>
						<CtaLink label={t('t12')} linkUrl="/book" />
					</div>
				</div>
				<div className="bg-light rounded p-4 my-5 ">
					<ContainerDonts />
				</div>
			</div>
		</Framework>
	);
};

Products.propTypes = {
	location: PropTypes.object.isRequired,
};

export default withLocation(Products);

export const query = graphql`
	query {
		locales: allTranslation(filter: { id: { ne: "dummy" } }) {
			...translationFields
		}
	}
`;
