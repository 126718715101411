import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { CupStraw, Egg, Wind, EmojiLaughing } from 'react-bootstrap-icons';

const containerDonts = () => {
	const { t } = useI18next();

	return (
		<>
			<section id="donts">
				<div className="container mt-md-5 mt-3">
					<h2 className="h4 mb-3 mb-lg-4 text-center">
						{t('component_container_donts:t1')}
					</h2>
					<div className="row">
						<div className="col-6 col-md-3 text-center mb-3">
							<div className="mb-2 h3">
								<CupStraw />
							</div>
							<div className="">{t('component_container_donts:t2')}</div>
						</div>
						<div className="col-6 col-md-3 text-center mb-3">
							<div className="mb-2 h3">
								<Wind />
							</div>
							{t('component_container_donts:t3')}
						</div>
						<div className="col-6 col-md-3 text-center">
							<div className="mb-2 h3">
								<Egg />
							</div>
							{t('component_container_donts:t4')}
						</div>
						<div className="col-6 col-md-3 text-center">
							<div className="mb-2 h3">
								<EmojiLaughing />
							</div>
							{t('component_container_donts:t5')}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default containerDonts;
