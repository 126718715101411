import { useI18next } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import CtaLink from '../../components/framework/cta-link';
import iconPCR from '../../images/framework/icon-pcr.svg';
import iconAntigen from '../../images/framework/icon-antigen.svg';

const ServiceListItem = ({ item }) => {
	const { t } = useI18next();

	return (
		<>
			<section id="service-item">
				<div
					className="card bg-primary-light
				 	rounded-0 o-hidden border-0"
				>
					<div className="card-body">
						<div className="row">
							<div className="col-auto pe-0">
								{item && item.product && item.product.type === 'antigen' && (
									<img
										alt=""
										className="img-fluid icon-service  mb-0 mb-lg-0"
										src={iconAntigen}
									/>
								)}
								{item && item.product && item.product.type === 'pcr' && (
									<img
										alt=""
										className="img-fluid icon-service mb-0 mb-lg-0"
										src={iconPCR}
									/>
								)}
							</div>
							<div className="col">
								<h6 className="card-subtitle small mt-1 o-50 ">
									{t(`${item.translation_key}_excerpt`)}
								</h6>
								<h5 className="card-title">{item.name}</h5>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<p className="x-small mb-0 ">{t('service_list_item:t2')}</p>
								<p className="text-primary fw-600">
									{t('service_list_item:t3')} {item.price_from}&#160;
									{item.currency.code}
								</p>
							</div>
							<div className="col">
								<p className="x-small mb-0">{t('service_list_item:t4')}</p>
								<p className="text-primary fw-600">
									{t(`${item.translation_key}_delivery_time`)}
								</p>
							</div>
						</div>

						<div className="card-text small">
							<p className="x-small mb-0">{t('service_list_item:t5')}</p>
							{t(`${item.translation_key}_description`)}
						</div>
						<CtaLink label={t('service_list_item:t6')} linkUrl="/book" />
						<CtaLink
							label={t('service_list_item:t7')}
							linkUrl={`/services/${item.slug}`}
						/>
					</div>
				</div>
			</section>
		</>
	);
};

ServiceListItem.propTypes = {
	item: PropTypes.object,
};

export default ServiceListItem;
